import { Disclosure } from '@headlessui/react'
import { Button } from '@pancakeswap/uikit'
import SocialLinks from '@pancakeswap/uikit/components/Footer/Components/SocialLinks'
import Image from 'next/image'
import Link from 'next/link'
import { AtomIcon, ETHIcon, HandIcon, LineaIcon, RocketIcon, SettingIcon } from './components/Icons'
import cloud from './images/cloud.png'
import illustration2 from './images/illustration2.png'

const faqData = [
  {
    question: 'What is Secta?',
    answer:
      'Secta is a platform that allows users to engage in direct trading on the Linea network through our comprehensive decentralized exchange (DEX). Additionally, Secta functions as a launchpad, providing investors with opportunities for early-stage funding to support promising ventures entering the Linea ecosystem.',
  },
  {
    question: 'Do you have a token?',
    answer:
      'SOON(™) In the spirit of building together from the ground up, our very first launchpad will be for the SECTA token itself.  Everyone will have a chance to participate and invest.  Everyone who wants an allocation will have the same chance with no special treatment, all fully verifiable on-chain.',
  },
  {
    question: 'Where can I learn more about Secta?',
    answer:
      'Feel free to take a look at our documentation page for more information about Secta.  Additionally, you can stay up-to-date with our frequent releases of new features and exciting projects by subscribing to our newsletter or following us on social media. This will give you exclusive access to the latest developments, regular updates, and the chance to engage',
  },
]

const visionData = [
  {
    icon: <LineaIcon width="56px" />,
    title: 'Linea Dedicated',
    description:
      'As Linea revolutionizes the next generation of layer-2 blockchain technology, Secta is devoted to developing the tools and features for users that empower users to fully leverage these advancements.',
  },
  {
    icon: <AtomIcon width="56px" />,
    title: 'Stellar Network',
    description:
      'We understand the significance of reputation and connections, and our council and internal team of analysts can assist with various aspects, including VC deals, developers, visionaries, and investors.',
  },
  {
    icon: <SettingIcon width="56px" />,
    title: 'Simple & Intuitive',
    description:
      'Secta provides a straightforward and user-friendly interface that prioritizes clarity and intuitive management of your trades and investments.',
  },
  {
    icon: <HandIcon width="56px" />,
    title: 'Future Forward',
    description:
      'As the Linea network continues to expand, we are charting a roadmap to bring you the latest DEX features you love. Take a look at our roadmap here to see the future of Secta.',
  },
]

const Home: React.FC<React.PropsWithChildren> = () => {
  return (
    <div className="grid-pattern before:bg-[length:24px_24px] md:before:bg-[length:48px_48px] flex flex-col">
      <div className="w-full absolute -z-10 mt-[400px]">
        <div className="w-full relative flex items-center justify-center overflow-hidden">
          <Image alt="cloud" src={cloud} />
          <Image alt="cloud" src={cloud} />
          <Image alt="cloud" src={cloud} />
        </div>

        <div className="w-full h-8 mb-2 md:h-12 md:mb-2 bg-[#EEDB76]" />
        <div className="w-full h-6 mb-3 md:h-8 md:mb-4 bg-[#E1846C]" />
        <div className="w-full h-4 mb-4 md:h-6  md:mb-6 bg-[#EDABD9]" />
        <div className="w-full h-2 mb-5 md:h-4  md:mb-8 bg-[#AFA4F5]" />
        <div className="w-full h-2 mb-5 md:h-2  md:mb-10 bg-[#B1D5DC]" />
        <div className="w-full h-1 mb-0 md:h-1  md:mb-0 bg-[#CADCB1]" />
      </div>

      <div className="container mx-auto my-12 md:my-24 max-w-6xl px-4 md:px-8">
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="w-full md:hidden flex flex-col items-center">
            <div className="w-full relative">
              <div className="font-title text-[#322E2A] text-[64px] uppercase text-center">
                YOUR GATEWAY To The Linea Ecosystem
              </div>
            </div>
          </div>

          <div className="w-full max-w-[980px] relative hidden md:block">
            <div className="font-title text-[#322E2A] text-[64px] md:text-[96px] uppercase text-center">
              <ETHIcon className="w-[80px] rotate-[-21deg] inline-block -mr-4" />
              YOUR GATEWAY <br /> TO THE LINEA ECOSYSTEM
            </div>
          </div>

          <div className="w-full flex flex-col items-center gap-8">
            <div className="text-lg font-description text-[#48433D] text-center font-medium max-w-[450px]">
              Secta is a decentralized exchange (DEX) and Launchpad fostering the growth of the Linea ecosystem by
              allowing the users to invest in projects to help grow Linea together with everyone’s incentives aligned.
            </div>
          </div>

          <div className="w-full flex flex-col gap-8 md:gap-16 md:flex-row mt-16">
            <div className="bg-[#F6F2EA] relative flex flex-col items-center gap-6 border-2 shadow-md-secta border-solid border-[#322E2A] rounded px-4 py-8 md:px-8 md:w-1/2">
              <ETHIcon className="size-[100px] mb-4" />
              <div className="font-title text-4xl md:text-5xl/tight uppercase text-[#322E2A] text-center">
                SWAP ON LINEA
                <br /> WITH EASE
              </div>
              <div className="text-lg font-description text-[#48433D] text-center min-h-28">
                Experience seamless decentralized trading on our intuitive DEX built exclusively for the Linea network.
                Swap Now.
              </div>
              <Button as={Link} href="/swap">
                Swap
              </Button>
            </div>

            <div className="bg-[#F6F2EA] relative flex flex-col items-center gap-6 border-2 shadow-md-secta border-solid border-[#322E2A] rounded px-4 py-8 md:px-8 md:w-1/2">
              <RocketIcon className="size-[100px] mb-4" />

              <div className="font-title text-4xl md:text-5xl/tight uppercase text-[#322E2A] text-center">
                EMPOWER PROJECTS
                <br /> ON LINEA
              </div>
              <div className="text-lg font-description text-[#48433D] text-center min-h-28">
                Eliminate unfair fundraises where users serve as VC exit liquidity. Our platform enables transparent,
                open-source investing with on-chain verifiability and equitable allocations for all.
              </div>
              <div className="flex flex-row gap-x-2">
                <Button disabled>Launchpad</Button>
                <Button as={Link} external href="https://forms.gle/Xfneh4A2aXBfU3ex6">
                  APPLY NOW
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-[#F6F2EA]">
        <div className="container mx-auto my-12 md:my-24 max-w-6xl px-4 md:px-8">
          <div className="flex flex-col justify-center md:flex-row-reverse items-center gap-8">
            <div className="basis-1/2">
              <Image alt="" src={illustration2} />
            </div>
            <div className="basis-1/2 flex flex-col gap-4 md:gap-8 max-w-md">
              <div className="font-title text-4xl md:text-5xl/tight uppercase text-[#322E2A] text-center md:text-left">
                WANT TO LAUNCH ON SECTA?
              </div>
              <div className="text-lg font-description text-[#48433D] text-center md:text-left">
                Secure funding, mitigate risks, and achieve significant visibility within the Linea Ecosystem. Tap into
                a worldwide network of committed supporters within a transparent and decentralized environment while
                cultivating a thriving community along the way.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <div className="w-full absolute -z-10 mt-[320px]">
          <div className="w-full h-8 mb-2 md:h-16 md:mb-2 bg-[#EEDB76]" />
          <div className="w-full h-6 mb-3 md:h-12 md:mb-4 bg-[#E1846C]" />
          <div className="w-full h-4 mb-4 md:h-8  md:mb-6 bg-[#EDABD9]" />
          <div className="w-full h-2 mb-5 md:h-6  md:mb-8 bg-[#AFA4F5]" />
          <div className="w-full h-2 mb-5 md:h-4  md:mb-10 bg-[#B1D5DC]" />
          <div className="w-full h-1 mb-0 md:h-2  md:mb-0 bg-[#CADCB1]" />
        </div>

        <div className="container mx-auto my-12 md:my-24 max-w-6xl px-4 md:px-8">
          <div className="flex flex-col justify-center items-center">
            <div className="max-w-md">
              <div className="font-title text-4xl md:text-5xl/tight uppercase text-[#322E2A] text-center leading-[58px] mb-8 md:mb-16">
                SECTA OFFERS STELLAR ADVANTAGES
              </div>
            </div>

            <div className="flex flex-row flex-wrap gap-8 justify-center">
              {visionData.map((item) => (
                <div
                  key={item.title}
                  className="bg-[#f6f2ea] p-8 border-[#48433D29] border-solid border-2 rounded w-full sm:w-[calc(50%-16px)] sm:max-w-[352px]"
                >
                  <div className="mb-6">{item.icon}</div>
                  <div className="font-description text-2xl font-medium mb-2 text-[#322E2A]">{item.title}</div>
                  <div className="font-description text-base font-medium text-[#48433D]">{item.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full bg-[#AFA4F5] relative"
        style={{
          backgroundImage: 'url(/images/banner.png)',
          backgroundPosition: 'center',
          backgroundSize: 'auto 100%',
        }}
      >
        <div className="container mx-auto my-12 md:my-24 max-w-6xl px-8 relative">
          <div className="flex flex-col gap-8 py-8 md:py-12">
            <div className="flex justify-center">
              <div className="font-title text-4xl md:text-5xl/tight text-center text-[#322E2A] uppercase max-w-[450px]">
                Launch Your Next Linea Project With Secta
              </div>
            </div>
            <div className="flex justify-center">
              <Button as={Link} external href="https://forms.gle/Xfneh4A2aXBfU3ex6">
                APPLY NOW
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto my-12 md:my-24 max-w-6xl px-4 md:px-8">
        <div className="flex flex-col-reverse justify-center md:flex-row-reverse items-center gap-8 md:items-start">
          <div className="w-full md:basis-1/2">
            <div className="w-full">
              <div className="flex flex-col gap-4">
                {faqData.map((d, index) => (
                  <Disclosure key={d.question} defaultOpen={index === 0}>
                    {({ open }) => (
                      <div
                        className={`px-6 py-5 rounded border-2 border-solid ${
                          open ? 'border-[#E0836C] shadow-md-secta-color' : 'border-[#48433D29]'
                        }`}
                      >
                        <Disclosure.Button className="py-2 w-full">
                          <div className="flex items-start justify-around">
                            <div className="text-[#322E2A] font-medium font-description text-xl flex-1 text-left">
                              {d.question}
                            </div>

                            <div className="">
                              {!open ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="#48433D" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path d="M19.0001 11V13H5.00012V11H19.0001Z" fill="#48433D" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="font-description text-sm text-[#48433D]">
                          {d.answer}
                        </Disclosure.Panel>
                      </div>
                    )}
                  </Disclosure>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full md:basis-1/2 flex flex-col gap-4 md:gap-8 max-w-md">
            <div className="font-title text-4xl md:text-5xl/tight uppercase text-[#322E2A] text-center md:text-left">
              Frequently Asked Questions
            </div>

            <div className="font-description text-base font-medium text-[#48433D]">
              Here is a quick overview of Secta. You can also get to know more about our platform by checking out our
              docs here.
            </div>
          </div>
        </div>
      </div>

      {/* newsletter */}
      <div className="w-full">
        <div className="block md:hidden w-full absolute -z-10 mt-[215px]">
          <div className="w-full h-8 mb-2 bg-[#EEDB76]" />
          <div className="w-full h-6 mb-3 bg-[#E1846C]" />
          <div className="w-full h-4 mb-4 bg-[#EDABD9]" />
          <div className="w-full h-2 mb-5 bg-[#AFA4F5]" />
          <div className="w-full h-2 mb-5 bg-[#B1D5DC]" />
          <div className="w-full h-1 mb-0 bg-[#CADCB1]" />
        </div>

        <div className="hidden md:block w-full absolute -z-10 mt-[170px]">
          <div className="w-full h-16 mb-2 bg-[#EEDB76]" />
          <div className="w-full h-12 mb-4 bg-[#E1846C]" />
          <div className="w-full h-8 mb-6 bg-[#EDABD9]" />
          <div className="w-full h-6 mb-8 bg-[#AFA4F5]" />
          <div className="w-full h-4 mb-10 bg-[#B1D5DC]" />
          <div className="w-full h-2 mb-0 bg-[#CADCB1]" />
        </div>

        <div className="container mx-auto my-12 md:my-24 px-4 md:px-8 relative">
          <div className="max-w-[512px] mx-auto rounded border-solid border-2 shadow-md-secta border-[#322E2A] bg-[#F6F2EA] flex flex-col items-stretch">
            <div className="px-8 py-12 md:py-16 flex flex-col items-center">
              <div className="font-title text-4xl md:text-5xl/tight text-center text-[#322E2A] uppercase mb-12 md:mb-16">
                JOIN THE SECTA COMMUNITY
              </div>

              <div className="font-description text-lg font-medium text-[#48433D] max-w-[320px] text-center">
                Join our social media community and stay ahead of the curve with exclusive access to our latest
                releases.
              </div>

              {/* <div className="relative w-full mb-4">
                <label htmlFor="email" className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Email address
                </label>
                <input
                  id="email"
                  className="block w-full bg-transparent h-14 pl-3 pr-12 border-[3px] border-solid border-[#48433D29] rounded font-['Roboto']"
                  placeholder="Enter your email"
                  type="email"
                />
                <div className="flex absolute inset-y-0 right-0 items-center pl-3">
                  <Button className="w-12 h-12" variant="text">
                    <SendIcon color="backgroundAlt" />
                  </Button>
                </div>
              </div> */}
            </div>

            <div className="py-5 md:py-8 bg-[#EEDB76] flex justify-center items-center border-t-[2px] rounded-b border-[#322E2A] border-solid">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
